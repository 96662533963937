.blog-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.blog-post {
  flex: 0 0 calc(33.3333% - 20px);
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

.blog-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.blog-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #FFFFFF;
}

.blog-excerpt {
  color: #555;
}

.blog-link {
  color: #007BFF;
  text-decoration: none;
  display: block;
  margin-top: 10px;
}
