/* BlogPost.css */

.blog-single-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.blog-single-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.blog-single-meta {
  color: #555;
  font-size: 14px;
}

.meta-item {
  display: block;
}

.blog-single-content {
  color: #333;
  line-height: 1.6;
}

.blog-link {
  color: #007BFF;
  text-decoration: none;
  display: block;
  margin-top: 10px;
}

/* You can add more styles based on your design preferences */
